import { z } from 'zod'
import { addressSchema } from '../address/schema'
import { profileSchema } from '../profile/schema'
import { baseSchema } from '../common/schema'

import { PaginationSchema } from '@/components/Pagination/schema'
import { stringValidator } from '@/utils/regex'

export const productServiceSchema = z.object({
    productServiceId: z.string().optional(),
    businessId: z.string(),
    type: z.string(),
    name: stringValidator('Product/Service Name', {
        isRequired: true,
    }),
    description: stringValidator('Product/Service Description', {
        isRequired: false,
    }),
    cost: z.number().min(1),
    markup: z.number().min(0).optional(),
    unitPrice: z.number().min(1),
    serviceImage: z.instanceof(File).or(z.string()).nullish(),
    isTaxExempt: z.boolean(),
    isAvailable: z.boolean().optional(),
})

export const productServiceGroupSchema = z.object({
    productServiceGroupID: z.string().optional(),
    productServiceId: z.string(),
    name: z.string(),
    type: z.string(),
    cost: z.number(),
    markup: z.number().optional(),
    unitPrice: z.number(),
    description: z.string().optional(),
    quantity: z.number().optional(),
})

export const expenseSchema = z.object({
    expenseId: z.string().optional(),
    quoteId: z.string().optional(),
    accountName: stringValidator('Expense name', { isRequired: true }),
    description: stringValidator('Description', { isRequired: false }),
    date: z.string(),
    total: z.coerce.number({
        invalid_type_error: 'Cost must be in valid currency format',
    }),
    isNotIncludedInInvoice: z.boolean().optional(),
    isSavedFromCrew: z.boolean().optional(),
})

export const quoteSchema = z
    .object({
        quoteId: z.string().optional(),
        quoteNumber: z.number().optional(),
        profileId: z.string(),
        profile: profileSchema.optional(),
        businessId: z.string(),
        productServiceGroupID: z.string().optional(),
        productServiceGroup: z.array(productServiceGroupSchema).min(1, {
            message: 'Product/Service must include at least 1 item',
        }),
        status: z.string(),
        addressId: z.string(),
        address: addressSchema.optional(),
        name: z.string(),
        laborCost: z.number(),
        subTotal: z.number().optional(),
        tax: z.number().optional(),
        taxName: z.string().optional(),
        serviceTax: z.number().optional(),
        productTax: z.number().optional(),
        expenseTax: z.number().optional(),
        taxId: z.string().optional(),
        discount: z.number().optional(),
        discountId: z.string().optional(),
        isDiscountPercent: z.boolean().optional(),
        isInvoiceSent: z.boolean().optional(),
        isQuoteCreated: z.boolean().optional(),
        showLaborCost: z.boolean().optional(),
        expense: z.array(expenseSchema).optional(), // TO DO: rename to expenses and modify forms
        totalAmount: z.number().optional(),
        internalNotes: z.string().optional(),
    })
    .merge(baseSchema.partial())

export const quoteContentSchema = z.object({
    totalProductsAndServices: z.number().default(0),
    totalUnitPrice: z.number().default(0),
    totalServicePrice: z.number().default(0),
    totalProduct: z.number().default(0),
    totalService: z.number().default(0),
    totalExpense: z.number().default(0),
    laborCost: z.number(),
    subTotal: z.number().default(0),
    discount: z.number().default(0),
    internalNotes: z.string().default(''),
})

export const quoteListSchema = z.object({
    content: z.array(quoteSchema),
    meta: PaginationSchema,
})

export const getJobQuoteSchema = z.object({
    isJobExist: z.boolean(),
    jobId: z.string().optional(),
})

export const bulkDeleteQuoteSchema = z.object({
    quoteIds: z.array(z.string()),
})

export type QuoteType = z.infer<typeof quoteSchema>

export type QuoteListType = z.infer<typeof quoteListSchema>

export type ExpenseType = z.infer<typeof expenseSchema>
export type ProductServiceType = z.infer<typeof productServiceGroupSchema>
export type QuoteContentType = z.infer<typeof quoteContentSchema>
export type GetJobQuoteType = z.infer<typeof getJobQuoteSchema>
export type ProductServiceGroupType = z.infer<typeof productServiceGroupSchema>
export type BulkDeleteQuoteType = z.infer<typeof bulkDeleteQuoteSchema>
export type CreateExpenseType = z.infer<typeof expenseSchema>
export type SingleProductService = z.infer<typeof productServiceSchema>
