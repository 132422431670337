import BreadCrumbs from '@/components/BreadCrumbs'
import { InvoiceReportFilter } from './InvoiceReportFilter'
import InvoiceReportTable from './InvoiceReportTable'
import { useSetAtom } from 'jotai'
import { invoiceReportFilterAtom } from '@/store/reports'
import { useEffect } from 'react'
export const InvoiceReport = () => {
    const setInvoiceReportFilter = useSetAtom(invoiceReportFilterAtom)

    useEffect(() => {
        setInvoiceReportFilter(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='flex flex-col w-full h-full px-[70px] pt-[50px] gap-5'>
            <BreadCrumbs titleName='Invoice' />
            <InvoiceReportFilter />
            <InvoiceReportTable />
        </div>
    )
}
