import React, { useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import { Card, CardContent } from '@/components/Card'
import { useQuery } from '@tanstack/react-query'
import './index.css'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, timezoneAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { getJobsCalendar } from '@/api/job'
import dayjs from 'dayjs'
import { EventClickArg, EventContentArg } from '@fullcalendar/core/index.js'
import { useLocation, useNavigate } from 'react-router-dom'
import { cn } from '@/utils/helper'
import Calendar from '@/assets/private/calendar.svg'
import { ISO_8601_WITH_UTC_OFFSET } from '@/constants'

interface CalendarComponentProps {
    handleEventClick?: (clickInfo: EventClickArg) => void
    handleEventMouseEnter?: (arg: EventClickArg) => void
    handleEventMouseLeave?: (arg: EventClickArg) => void
    isUnassigedToggled?: boolean
    setIsUnassigedToggled?: React.Dispatch<React.SetStateAction<boolean>>
}

const CalendarComponent = ({
    handleEventClick,
    handleEventMouseEnter,
    handleEventMouseLeave,
    isUnassigedToggled,
    setIsUnassigedToggled,
}: CalendarComponentProps) => {
    const [calendarVisible, setCalendarVisible] = useState(true)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const navigate = useNavigate()
    const location = useLocation()
    const timeZone = useAtomValue(timezoneAtom)
    const pathName = location.pathname
    const [start, setStart] = useState<string>('')
    const [end, setEnd] = useState<string>('')

    const { data } = useQuery({
        queryKey: [
            'jobsCalendar',
            user?.businessId,
            isUnassigedToggled,
            setIsUnassigedToggled,
            start,
            end,
        ],
        queryFn: () =>
            getJobsCalendar(
                user?.businessId as string,
                start,
                end,
                isUnassigedToggled ? 'unassigned' : '',
            ),
        enabled: !!user?.businessId && !!start && !!end,
        select: (res) => {
            if (Array.isArray(res) && res?.length > 0) {
                const jobs = res
                const schedules = jobs?.map((job) => ({
                    id: job.jobId,
                    publicId: job.jobId,
                    title: job.quote.name,
                    start: dayjs(job.appointment.startDateTime)
                        .tz(timeZone?.timeZoneId)
                        .format('YYYY-MM-DDTHH:mm:ssZ'),
                    end: dayjs(job.appointment.endDateTime)
                        .tz(timeZone?.timeZoneId)
                        .format('YYYY-MM-DDTHH:mm:ssZ'),
                    color: job?.crew?.crewColor,
                }))
                return [...schedules]
            }

            return []
        },
    })

    const renderEventContent: React.FC<EventContentArg> = ({ backgroundColor, event, view }) => {
        const isTimeGridView = view.type === 'timeGridWeek' || view.type === 'timeGridDay'

        const background = cn(
            `linear-gradient(to right, ${backgroundColor} 5px, ${backgroundColor}20 5px)`,
        )
        return (
            <div className='event-content w-full h-full overflow-hidden'>
                <button
                    className='truncate w-full pl-3 pr-4 py-1 text-zentive-black'
                    style={{ background }}
                    type='button'
                >
                    {event.title}
                </button>
                {isTimeGridView && (
                    <p className='truncate text-nowrap pl-3 text-xs text-zentive-black'>
                        {dayjs(event.start).utc().format('hh:mm A')} -{' '}
                        {dayjs(event.end).utc().format('hh:mm A')}
                    </p>
                )}
            </div>
        )
    }

    const toggleCalendarVisibility = () => {
        setCalendarVisible((prev) => !prev)
    }

    return (
        <Card className='w-full mt-7 rounded-none'>
            <CardContent className='p-6 rounded-none'>
                <div className='flex flex-row justify-between mb-4 mt-1 font-semibold'>
                    <div> Calendar </div>
                    <div>
                        <button
                            className='text-zentive-green-dark'
                            onClick={toggleCalendarVisibility}
                        >
                            {calendarVisible ? 'Hide Calendar' : 'Show Calendar'}
                        </button>
                    </div>
                </div>
                {calendarVisible ? (
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin]}
                        timeZone={timeZone?.timeZoneId}
                        initialView='dayGridMonth'
                        events={data}
                        eventContent={renderEventContent}
                        eventTimeFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            meridiem: 'short',
                        }}
                        slotEventOverlap={false}
                        eventOverlap={false}
                        displayEventTime={false}
                        navLinks={true}
                        headerToolbar={{
                            start: 'today,prev',
                            center: 'title',
                            end: `next dayGridMonth,timeGridWeek,timeGridDay${
                                pathName === '/schedule/calendar' ? ',myCustomButton' : ''
                            }`,
                        }}
                        customButtons={{
                            myCustomButton: {
                                text: 'Create Job',
                                click: () => {
                                    navigate('/schedule/jobs/new-job')
                                },
                            },
                        }}
                        themeSystem='standard'
                        eventClick={handleEventClick}
                        eventMouseEnter={handleEventMouseEnter}
                        eventMouseLeave={handleEventMouseLeave}
                        dayHeaderClassNames={'header'}
                        datesSet={(data) => {
                            const start = dayjs(data.start)
                                .hour(0)
                                .minute(0)
                                .second(0)
                                .format(ISO_8601_WITH_UTC_OFFSET)
                            const end = dayjs(data.end)
                                .tz(timeZone?.timeZoneId)
                                .hour(0)
                                .minute(0)
                                .second(0)
                                .format(ISO_8601_WITH_UTC_OFFSET)
                            setStart(start)
                            setEnd(end)
                        }}
                        // dayHeaderContent={({ date }) => <DayHeader date={date} />}
                    />
                ) : (
                    <div className='flex flex-col items-center justify-center text-zentive-gray-medium gap-2 mt-12'>
                        <div>
                            <img src={Calendar} alt='email-logo' className='w-full h-24' />
                        </div>
                        <div className='font-semibold mt-2'> Calendar is Hidden </div>
                        <div> Click Show Calendar above to display calendar </div>
                    </div>
                )}
            </CardContent>
        </Card>
    )
}

export default CalendarComponent
