import BreadCrumbs from '@/components/BreadCrumbs'
import { NetGrossTabs } from './NetGrossTabs'
import { netGrosstabsATom } from '@/store/reports'
import { useAtomValue, useSetAtom } from 'jotai'
import { netGrossFilterAtom } from '@/store/reports'
import { NET_GROSS_TABS } from '@/constants'
import GrossTable from './GrossTable'
import NetTable from './NetTable'
import { GrossNetFilter } from './GrossNetFilter'
import { useEffect } from 'react'
export const GrossAndNetIncome = () => {
    const tab = useAtomValue(netGrosstabsATom)
    const setNetGrossFilter = useSetAtom(netGrossFilterAtom)

    useEffect(() => {
        setNetGrossFilter(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='flex flex-col w-full h-full px-[70px] pt-[50px] gap-5'>
            <BreadCrumbs titleName='Gross Net Income' />
            <GrossNetFilter />
            <NetGrossTabs />
            {tab === NET_GROSS_TABS.gross ? <GrossTable /> : <NetTable />}
        </div>
    )
}
