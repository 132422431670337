import { BusinessStatusType, ProfileBusinessType } from '@/api/business/schema'
import { getProfileById } from '@/api/profile'
import { Checkbox } from '@/components/Checkbox'
import { TableBody, TableCell, TableRow } from '@/components/Table'
import Tooltip from '@/components/Tooltip'
import { USER_STATUS } from '@/constants'
import { isViewedAsAtom, isViewedDismissedAtom, viewedAsUserAtom } from '@/store/auth'
import {
    manageCurrentSubTypeAtom,
    manageInfoTabAtom,
    manageSubsTabAtom,
    manageUserIDAtom,
    toExportSubsAtom,
} from '@/store/manageSubs'
import { cn, formatPhoneNumber, numberFormatter } from '@/utils/helper'
import { extractUserReponse } from '@/utils/user'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { FC, useEffect } from 'react'
import { FaEye } from 'react-icons/fa'
import { IoIosWarning } from 'react-icons/io'
import { useMediaQuery } from 'react-responsive'
import { NavLink } from 'react-router-dom'
import ContactedButton from './ContactedButton'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { E164Number } from 'libphonenumber-js/core'
import NoteModal from '@/components/NotesModal'
import { SubscriberProps } from '../..'

const T_CELL_CLASS = 'text-zentive-black text-base'

const SubscribersTableBody: FC<SubscriberProps> = ({ subscriberList }) => {
    const { navigateTo } = useAppNavigate()

    const [owner, setOwner] = useAtom(viewedAsUserAtom)
    const setIsViewedAsOwnerDismissed = useSetAtom(isViewedDismissedAtom)
    const [toExportBusinessData, setToExportBusinessData] = useAtom(toExportSubsAtom)

    const setCurrentSubType = useSetAtom(manageCurrentSubTypeAtom)
    const setProfileId = useSetAtom(manageUserIDAtom)
    const setSelected = useSetAtom(manageInfoTabAtom)
    const setIsViewedAs = useSetAtom(isViewedAsAtom)

    const subStatus = useAtomValue(manageSubsTabAtom)

    const handleCheckClick = (subscriber: ProfileBusinessType, isChecked: boolean) => {
        setToExportBusinessData((prev: ProfileBusinessType[] | null) => {
            if (prev === null) {
                return isChecked ? [subscriber] : null
            }
            return isChecked
                ? [...prev, subscriber]
                : prev.filter((sub: ProfileBusinessType) => sub.profileId !== subscriber.profileId)
        })
    }

    const handleSetOwner = async (profileId: string) => {
        try {
            await getProfileById(profileId).then((res) => setOwner(extractUserReponse(res)))

            setIsViewedAsOwnerDismissed(true)
            setIsViewedAs(true)
            navigateTo('/dashboard')
        } catch (err) {
            if (err instanceof AxiosError) {
                console.error(err.message)
            }
        }
    }

    useEffect(() => {
        if (owner === null) setToExportBusinessData([])
    }, [owner])

    const xl_vw_already = useMediaQuery({ maxWidth: 1370 })
    const xxl_vw_already = useMediaQuery({ maxWidth: 1620 })

    return (
        <TableBody className='bg-white'>
            {subscriberList?.map((data) => (
                <TableRow key={data.profileId} className='whitespace-nowrap'>
                    <TableCell className={cn(T_CELL_CLASS, '')}>
                        <div className='flex items-start text-start gap-[12px]'>
                            <Checkbox
                                id={'business-name' + ' ' + data.business.businessName}
                                checked={toExportBusinessData?.some(
                                    (subs) => subs.profileId === data.profileId,
                                )}
                                className='mr-[5px] self-center mb-1'
                                onCheckedChange={(val) => handleCheckClick(data, val as boolean)}
                            />
                            <NavLink
                                onClick={() => {
                                    setSelected('personalInformation')
                                    setCurrentSubType(
                                        data.business.subscriptionType
                                            ?.subscriptionTypeId as string,
                                    )
                                    setProfileId(data.profileId)
                                }}
                                to={`/manage-subs/subscribers/details?profileid=${
                                    data.profileId
                                }&corporateid=${data.businessId}&expiryDate=${
                                    data.business.subscriptionExpiryDate !== undefined
                                        ? data.business.subscriptionExpiryDate
                                        : data.createdAt
                                }`}
                                className='hover:text-zentive-green-dark text-ellipsis overflow-hidden mb-1'
                            >
                                {data.business.businessName}
                            </NavLink>
                            {false && (
                                <IoIosWarning className={'text-zentive-purple-dark mt-[2px]'} />
                            )}
                            {data.business.status !== USER_STATUS.TERMINATED && (
                                <button
                                    type='button'
                                    onClick={() => handleSetOwner(data.profileId)}
                                >
                                    <Tooltip position='bottom' alignment='start' content='View As'>
                                        <FaEye className={'mt-1 text-gray-500'} />
                                    </Tooltip>
                                </button>
                            )}
                        </div>
                    </TableCell>
                    <TableCell className={cn(T_CELL_CLASS, '')}>
                        <div className='w-full flex justify-center mb-1'>
                            {data.firstName + ' ' + data.lastName}
                        </div>
                    </TableCell>
                    <TableCell className={cn(T_CELL_CLASS, '')}>
                        <div className='text-end items-end pr-8 mb-1'>
                            ${numberFormatter.format(data?.business?.subscriptionType?.price ?? 0)}
                        </div>
                    </TableCell>
                    <TableCell className={cn(T_CELL_CLASS, '')}>
                        <div className='text-end items-end pr-8 mb-1'>
                            {data?.business?.subscriptionType?.interval === 'month'
                                ? 'Monthly'
                                : data?.business?.subscriptionType?.interval === 'year'
                                  ? 'Annual'
                                  : data?.business?.subscriptionType?.interval === 'days'
                                    ? 'Free Trial'
                                    : '14 days free trial '}
                        </div>
                    </TableCell>
                    {subStatus === USER_STATUS.TERMINATED && (
                        <div className='w-full flex justify-center mt-1'>
                            <ContactedButton
                                updateContactedValue={
                                    {
                                        businessId: data.businessId,
                                        isContacted: data.business.contacted,
                                    } as BusinessStatusType
                                }
                            />
                        </div>
                    )}
                    <TableCell className={cn(T_CELL_CLASS, '')}>
                        <Tooltip position='bottom' alignment='start' content={data.email}>
                            <p
                                className={cn(
                                    xxl_vw_already ? 'w-24 truncate' : 'w-full',
                                    xl_vw_already && 'w-full',
                                )}
                            >
                                {data.email}
                            </p>
                        </Tooltip>
                    </TableCell>
                    <TableCell className={cn(T_CELL_CLASS, '')}>
                        <Tooltip
                            position='bottom'
                            alignment='start'
                            content={
                                data.address.streetAddress +
                                ', ' +
                                data.address.city +
                                ', ' +
                                data.address.state +
                                ', ' +
                                data.address.zipCode
                            }
                        >
                            <p
                                className={cn(
                                    xxl_vw_already ? 'w-24 truncate' : 'w-full',
                                    xl_vw_already && 'w-full',
                                )}
                            >
                                {data.address.streetAddress}
                            </p>
                        </Tooltip>
                    </TableCell>
                    <TableCell className={cn(T_CELL_CLASS, '')}>
                        <Tooltip
                            position='bottom'
                            alignment='start'
                            content={formatPhoneNumber(data.phoneNumber as E164Number)}
                        >
                            <p
                                className={cn(
                                    xxl_vw_already ? 'w-24 truncate' : 'w-full',
                                    xl_vw_already && 'w-full',
                                )}
                            >
                                {formatPhoneNumber(data.phoneNumber as E164Number)}
                            </p>
                        </Tooltip>
                    </TableCell>
                    <TableCell className={cn(T_CELL_CLASS, '')}>
                        <Tooltip
                            position='bottom'
                            alignment='start'
                            content={dayjs(data.createdAt).format('MMMM DD, YYYY')}
                        >
                            <p
                                className={cn(
                                    xxl_vw_already ? 'w-24 truncate' : 'w-full',
                                    xl_vw_already && 'w-full',
                                )}
                            >
                                {dayjs(data.createdAt).format('MMMM DD, YYYY')}
                            </p>
                        </Tooltip>
                    </TableCell>
                    <TableCell>
                        <div className='ml-2'>
                            <Tooltip position='bottom' alignment='start' content='Notes'>
                                <NoteModal profileId={data.profileId} />
                            </Tooltip>
                        </div>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )
}

export default SubscribersTableBody
